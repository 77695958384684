/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_content_delivery_bucket: "apsfrontend-20210203185048-hostingbucket-dev",
  aws_content_delivery_bucket_region: "ap-southeast-1",
  aws_content_delivery_url:
    "http://apsfrontend-20210203185048-hostingbucket-dev.s3-website-ap-southeast-1.amazonaws.com",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:3511c6e4-6534-442c-838f-126c2f2e306b",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_JYTvL9Xsr",
  aws_user_pools_web_client_id: "1gnkpa5bvs2026qtj2crue41p5",
  oauth: {},
  bucket: "ktppsea-resources-dev",
  region: "ap-southeast-1",
};

export default awsmobile;
