const currentUser = (state) => {
  return state.user;
};

const isAuthenticated = (state) => {
  return state.isAuthenticated;
};

const currentRole = (state) => {
  return state.role;
};

const currentPermission = (state) => {
  return state.permission;
};

export default {
  currentUser,
  isAuthenticated,
  currentRole,
  currentPermission,
};
