import { Auth } from "aws-amplify";
import api from "@/api/apiServices";
import store from "../store/index.js";

export function vuexLoginGuard(router) {
  return (to, from, next) => {
    //Check if previous visit is current
    var now = new Date();
    var hour = 60 * 60 * 1000;
    if (localStorage.ktppsLastVisit) {
      var lastVisit = new Date(localStorage.ktppsLastVisit);
      if (now - lastVisit > hour) {
        api.addVisit().then(() => {
          localStorage.ktppsLastVisit = now;
        });
      } else {
        localStorage.ktppsLastVisit = now;
      }
    } else {
      api.addVisit().then(() => {
        localStorage.ktppsLastVisit = now;
      });
    }
    // Check last login
    var user = store.getters["security/currentUser"];
    if (user["username"]) {
      var lastLogin = new Date(user["attributes"]["custom:lastLogin"]);
      if (now - lastLogin > hour) {
        Auth.updateUserAttributes(user, {
          "custom:lastLogin": now,
        });
      }
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (store.getters["security/isAuthenticated"]) {
        Auth.currentAuthenticatedUser({})
          .then((user) => {
            router.app.$store.commit("security/setAuth", user);
          })
          .catch(() => {});
      }
      if (!router.app.$store.getters["security/isAuthenticated"]) {
        next({ name: "Login" });
      } else {
        next(); // go to wherever I'm going
      }
    } else {
      next(); // does not require auth, make sure to always call next()!
    }
  };
}
