import Vue from 'vue'
import Vuex from 'vuex'

import security from "./security";
import theme from "./theme";

Vue.use(Vuex)

const createStore = () => {
    return new Vuex.Store({
        modules: {
            security: security,
            theme: theme
        }
    });
};

export default new createStore;