const setAuth = (state, user) => {
  state.isAuthenticated = true;
  state.user = user;
};

const purgeAuth = (state) => {
  state.isAuthenticated = false;
  state.user = {};
};

const setPermission = (state, data) => {
  state.role = data.role;
  state.permission = data.permission;
};

export default {
  setAuth,
  purgeAuth,
  setPermission,
};
