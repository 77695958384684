import Vue from "vue";
import VueRouter from "vue-router";
import { vuexLoginGuard } from "./guard.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/Home.vue"),
    meta: { title: "Home", breadcrumb: [{ name: "Home" }] },
  },
  {
    path: "/launch",
    name: "Launch",
    component: () =>
      import(/* webpackChunkName: "launch" */ "@/views/launch/Launch.vue"),
    meta: { title: "Launch", breadcrumb: [{ name: "Launch" }] },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/About.vue"),
    meta: { title: "About", breadcrumb: [{ name: "About" }] },
  },
  {
    path: "/preceptory/:id",
    name: "PreceptoryId",
    component: () =>
      import(
        /* webpackChunkName: "preceptory" */ "@/views/preceptory/IndividualPreceptory.vue"
      ),
    meta: (route) => ({
      title: "IndividualPreceptory",
      breadcrumb: [
        { name: "Preceptory", link: "/preceptory" },
        { name: route.params.id },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/preceptory/:id/photo",
    name: "Photo",
    props: true,
    component: () =>
      import(/* webpackChunkName: "preceptory" */ "@/views/gallery/Photo.vue"),
    meta: (route) => ({
      title: "Photo",
      breadcrumb: [
        { name: "Preceptory", link: "/preceptory" },
        {
          name: route.params.id,
          link: "/preceptory/" + route.params.id,
        },
        {
          name: "Photo",
        },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/preceptory/:id/library",
    name: "Library",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "preceptory" */ "@/views/library/Library.vue"
      ),
    meta: (route) => ({
      title: "Library",
      props: true,
      breadcrumb: [
        { name: "Preceptory", link: "/preceptory" },
        {
          name: route.params.id,
          link: "/preceptory/" + route.params.id,
        },
        { name: "Library" },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/preceptory/:id/charity",
    name: "Charity",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "preceptory" */ "@/views/charity/Charity.vue"
      ),
    meta: (route) => ({
      title: "Charitable Events",
      breadcrumb: [
        { name: "Preceptory", link: "/preceptory" },
        {
          name: route.params.id,
          link: "/preceptory/" + route.params.id,
        },
        { name: "Charitable Events" },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/preceptory/:id/presentation",
    name: "Presentation",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "preceptory" */ "@/views/presentation/Presentation.vue"
      ),
    meta: (route) => ({
      title: "Presentation",
      breadcrumb: [
        { name: "Preceptory", link: "/preceptory" },
        {
          name: route.params.id,
          link: "/preceptory/" + route.params.id,
        },
        { name: "Presentations" },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/preceptory/:id/summon",
    name: "Summon",
    props: true,
    component: () =>
      import(/* webpackChunkName: "preceptory" */ "@/views/summon/Summon.vue"),
    meta: (route) => ({
      title: "Summon",
      breadcrumb: [
        { name: "Preceptory", link: "/preceptory" },
        {
          name: route.params.id,
          link: "/preceptory/" + route.params.id,
        },
        { name: "Summons" },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/preceptory",
    name: "Preceptory",
    component: () =>
      import(
        /* webpackChunkName: "preceptory" */ "@/views/preceptory/Preceptory.vue"
      ),
    meta: {
      title: "Preceptory",
      breadcrumb: [{ name: "Preceptory" }],
      requiresAuth: true,
    },
  },
  {
    path: "/priory",
    name: "Priory",
    component: () =>
      import(/* webpackChunkName: "priory" */ "@/views/priory/Priory.vue"),
    meta: {
      title: "Priory",
      breadcrumb: [{ name: "Priory" }],
      requiresAuth: true,
    },
  },
  {
    path: "/priory/:id",
    name: "PrioryId",
    component: () =>
      import(
        /* webpackChunkName: "priory" */ "@/views/priory/IndividualPriory.vue"
      ),
    meta: (route) => ({
      title: "IndividualPriory",
      breadcrumb: [
        { name: "Priory", link: "/priory" },
        { name: route.params.id },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/priory/:id/photo",
    name: "PrioryPhoto",
    props: true,
    component: () =>
      import(/* webpackChunkName: "priory" */ "@/views/gallery/Photo.vue"),
    meta: (route) => ({
      title: "Photo",
      breadcrumb: [
        { name: "Priory", link: "/priory" },
        {
          name: route.params.id,
          link: "/priory/" + route.params.id,
        },
        {
          name: "Photo",
        },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/priory/:id/library",
    name: "PrioryLibrary",
    props: true,
    component: () =>
      import(/* webpackChunkName: "priory" */ "@/views/library/Library.vue"),
    meta: (route) => ({
      title: "Library",
      breadcrumb: [
        { name: "Priory", link: "/priory" },
        {
          name: route.params.id,
          link: "/priory/" + route.params.id,
        },
        { name: "Library" },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/priory/:id/charity",
    name: "PrioryCharity",
    props: true,
    component: () =>
      import(/* webpackChunkName: "priory" */ "@/views/charity/Charity.vue"),
    meta: (route) => ({
      title: "Charitable Events",
      breadcrumb: [
        { name: "Priory", link: "/priory" },
        {
          name: route.params.id,
          link: "/priory/" + route.params.id,
        },
        { name: "Charitable Events" },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/priory/:id/presentation",
    name: "PrioryPresentation",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "priory" */ "@/views/presentation/Presentation.vue"
      ),
    meta: (route) => ({
      title: "Presentation",
      breadcrumb: [
        { name: "Priory", link: "/priory" },
        {
          name: route.params.id,
          link: "/priory/" + route.params.id,
        },
        { name: "Presentations" },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/priory/:id/summon",
    name: "PriorySummon",
    props: true,
    component: () =>
      import(/* webpackChunkName: "priory" */ "@/views/summon/Summon.vue"),
    meta: (route) => ({
      title: "Summon",
      breadcrumb: [
        { name: "Priory", link: "/priory" },
        {
          name: route.params.id,
          link: "/priory/" + route.params.id,
        },
        { name: "Summons" },
      ],
      requiresAuth: true,
    }),
  },
  {
    path: "/member",
    name: "Member",
    component: () =>
      import(/* webpackChunkName: "member" */ "@/views/member/Member.vue"),
    meta: {
      title: "Member",
      breadcrumb: [{ name: "Members" }],
      requiresAuth: true,
    },
  },
  {
    path: "/member/add",
    name: "AddMember",
    component: () =>
      import(/* webpackChunkName: "member" */ "@/views/member/AddMember.vue"),
    meta: {
      title: "AddMember",
      breadcrumb: [{ name: "Members", link: "/member" }, { name: "Add" }],
      requiresAuth: true,
    },
  },
  {
    path: "/member/edit",
    name: "EditMember",
    component: () =>
      import(/* webpackChunkName: "member" */ "@/views/member/EditMember.vue"),
    meta: {
      title: "EditMember",
      breadcrumb: [{ name: "Members", link: "/member" }, { name: "Edit" }],
      requiresAuth: true,
    },
  },
  {
    path: "/permission",
    name: "Permission",
    component: () =>
      import(
        /* webpackChunkName: "permission" */ "@/views/permission/Permission.vue"
      ),
    meta: {
      title: "Permission",
      breadcrumb: [{ name: "Permissions" }],
      requiresAuth: true,
    },
  },
  {
    path: "/permission/add",
    name: "AddPermission",
    component: () =>
      import(
        /* webpackChunkName: "permission" */ "@/views/permission/AddPermission.vue"
      ),
    meta: {
      title: "AddPermission",
      breadcrumb: [
        { name: "Permissions", link: "/permission" },
        { name: "Add" },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/permission/edit",
    name: "EditPermission",
    component: () =>
      import(
        /* webpackChunkName: "permission" */ "@/views/permission/EditPermission.vue"
      ),
    meta: {
      title: "EditPermission",
      breadcrumb: [
        { name: "Permissions", link: "/permission" },
        { name: "Edit" },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/Register.vue"),
    meta: {
      title: "Register",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/Login.vue"),
  },
  {
    path: "/404",
    name: "Page404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/ResetPassword.vue"),
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/ChangePassword.vue"),
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/ForgotPassword.vue"),
  },
  {
    path: "/forgotPasswordConfirm",
    name: "ForgotPasswordConfirm",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "@/views/auth/ForgotPasswordConfirm.vue"
      ),
  },
  {
    path: "/resetConfirmPassword",
    name: "ResetConfirmPassword",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "@/views/auth/ResetPasswordConfirm.vue"
      ),
  },
  {
    path: "/admin/init",
    name: "AdminInit",
    props: true,
    component: () =>
      import(/* webpackChunkName: "AdminInit" */ "@/views/auth/AdminInit.vue"),
  },
  {
    path: "/2fa",
    name: "TwoFA",
    props: true,
    component: () =>
      import(/* webpackChunkName: "TwoFA" */ "@/views/auth/TwoFA.vue"),
  },
  {
    path: "/accountsetup",
    name: "AccountSetup",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "AccountSetup" */ "@/views/auth/AccountSetup.vue"
      ),
  },

  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
  });

const router = createRouter();

router.beforeEach(vuexLoginGuard(router));

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
