import { Auth } from "aws-amplify";
import api from "@/api/apiServices";

// put asynchronous functions that can call one or more mutation functions
const LOGIN = async (context, { email, password }) => {
  return Auth.signIn(email, password);
};

const NEW_PASSWORD = async (context, { email, password }) => {
  return Auth.completeNewPassword(email, password);
};

const GET_USER_ATTRIBUTE = async (context, { user }) => {
  return Auth.userAttributes(user);
};

const GET_USER_ROLE = async (context, { role }) => {
  return api.getRolePermission(role);
};

export default {
  LOGIN,
  NEW_PASSWORD,
  GET_USER_ATTRIBUTE,
  GET_USER_ROLE,
};
