<template>
  <div
    id="app"
    class="min-h-screen 
           bg-indigo-50 text-indigo-700 
           dark:bg-indigo-900 dark:text-indigo-50"
  >
    <router-view />
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  name: "App",
  methods: {
    async logout() {
      try {
        await Auth.signOut();
      } catch (error) {
        alert(error.message);
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("theme/initTheme", null, { root: true });
  },
};
</script>

<style>
@import url("https://rsms.me/inter/inter.css");
#app {
  font-family: "Inter var", Helvetica, Arial, sans-serif;
}
</style>
